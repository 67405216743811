
import { Component, Mixins } from 'vue-property-decorator';
import {
  PageTitle,
  TextField,
  Stepper,
  StepperStep,
  SuccessDialog,
} from '@/components';
import { attractions_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import Data from '../components/attraction-form-steps/BaseAttractionDataForm.vue';
import Fields from '../components/attraction-form-steps/FieldsAttractionForm.vue';
import { Attractions } from '../types';

const { state, actions } = attractions_store;

@Component({
  components: {
    PageTitle,
    TextField,
    Data,
    Fields,
    Stepper,
    StepperStep,
    SuccessDialog,
  },
})
export default class AddNewAttraction extends Mixins(ErrorHandlereMixin) {
  async mounted() {
    try {
      const idx = this.$route.params.id;

      await actions.set_current_attraction_by_id(parseInt(idx, 10));
      this.loading = false;
    } catch (error) {
      this.handleServerError(error);
    }
  }

  step = 1;

  success_dialog = false;

  loading = true;

  get attraction() {
    return state.current_attraction;
  }

  get fields(): Attractions.TimeslotTableCols[] {
    return this.attraction.fields?.length
      ? this.attraction.fields
      : state.base_attraction_fields;
  }

  submit_first_step() {
    this.step += 1;
  }

  to_attractions() {
    this.$router.push({
      name: Attractions.Pages.ATTRACTIONS,
    });
  }

  async submit_last_step(val: Attractions.TimeslotTableCols[]) {
    try {
      this.attraction.fields = val;
      await actions.update_attraction(this.attraction);

      this.success_dialog = true;
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
